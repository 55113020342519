import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { PageState } from '~/providers/page/pageState';

export const useAppStore = defineStore('app', () => {
    const pageState = ref('');
    const pageReady = computed(
        () =>
            pageState.value === PageState.EnterStart || pageState.value === PageState.EnterComplete
    );
    const firstPageRoute = ref(true);

    const setPageState = (val: string) => {
        pageState.value = val;
        // console.log('🚀 ~ setPageState ~ val:', val);

        switch (val) {
            case PageState.LeaveStart:
                setScrollPaused(true);
                setLoadProgress(0);
                break;
            case PageState.EnterStart:
                setScrollPaused(false);
                break;

            default:
                break;
        }
    };

    const loadProgress = ref(0);
    const setLoadProgress = (val: number) => {
        loadProgress.value = val;
    };

    const scrollPaused = ref(true);
    const setScrollPaused = function (val: boolean) {
        scrollPaused.value = val;
    };

    return {
        pageReady,
        pageState,
        firstPageRoute,
        setPageState,
        loadProgress,
        setLoadProgress,
        scrollPaused,
        setScrollPaused,
    };
});
